import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';
import { COLORS, MEDIA_QUERY } from '../../commonStyles';

const AdditionalQuestionsContainer = styled.div({
  width: '480px',
  margin: '120px auto 0',
  marginTop: '120px',
  [MEDIA_QUERY.TABLET]: {
    width: '450px',
    marginTop: '96px',
  },
  [MEDIA_QUERY.MOBILE]: {
    margin: '56px 20px 0',
    width: 'auto',
  },
});

const Paragraph = styled.p({
  width: '100%',
  fontSize: '18px',
  lineHeight: '28px',
  margin: 0,
  color: COLORS.DARK_GREY,
  a: {
    color: COLORS.BLACK,
  },
  b: {
    display: 'block',
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 'bold',
    color: COLORS.BLACK,
  },
  '+ p': {
    paddingTop: '36px',
  },
  [MEDIA_QUERY.TABLET]: {
    '+ p': {
      paddingTop: '18px',
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '16px',
    lineHeight: '24px',
    '+ p': {
      paddingTop: '18px',
    },
  },
});

type AdditionalQuestionsProps = {
  data: {
    json: string;
  };
};

const AdditionalQuestions: React.FC<AdditionalQuestionsProps> = ({ data }) => {
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    },
  };

  return (
    <AdditionalQuestionsContainer>
      {documentToReactComponents(data.json, options)}
    </AdditionalQuestionsContainer>
  );
};

export default AdditionalQuestions;
