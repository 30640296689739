import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { MEDIA_QUERY, COLORS } from '../../commonStyles';

const MarkDownContainer = styled.div({
  fontFamily: 'Arial',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 1.56,
  fontSize: '18px',
  letterSpacing: 'normal',
  color: COLORS.DARK_GREY,
  p: {
    a: {
      color: COLORS.DARK_GREY,
      textDecoration: 'underline',
      targetNew: 'tab',
    },
  },
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '16px',
  },
  h1: {
    fontSize: 'inherit',
  },
  h2: {
    fontSize: 'inherit',
  },
  h3: {
    fontSize: 'inherit',
  },
  lu: {
    fontSize: 'inherit',
  },
  li: {
    fontSize: 'inherit',
  },
  div: {
    fontSize: 'inherit',
  },
  span: {
    fontSize: 'inherit',
  },
});

export interface MarkdownRendererProps {
  markdown: string;
}

export const MarkdownRenderer: React.FC<MarkdownRendererProps &
  HTMLAttributes<HTMLDivElement>> = ({ markdown, ...props }) => (
  <MarkDownContainer {...props}>
    <ReactMarkdown source={markdown} linkTarget={'_blank'} />
  </MarkDownContainer>
);
