import React, { useEffect, useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { get } from 'lodash';

import { Layout } from '../../components';
import { COLORS, MEDIA_QUERY } from '../../commonStyles';
import * as Icon from '../../assets/icons';
import { MarkdownRenderer } from '../../components/markdown-renderer';
import { slugify } from '../../functions/utils';
import AdditionalQuestions from './additional-questions';

type Props = {
  language: string;
};

type Answer = {
  answer: string;
};

type Question = {
  question: string;
  answer: Answer;
  order: number;
};

type Category = {
  name: string;
  questions: Question[];
};

type AdditionalQuestions = {
  json: string;
};

type FaqPage = {
  pageTitle: string;
  categories: Category[];
  additionalQuestions: AdditionalQuestions;
};

const getData = (language: string) => {
  const { allContentfulFaq } = useStaticQuery(graphql`
    query FAQQuery {
      allContentfulFaq {
        nodes {
          categories {
            name
            questions {
              answer {
                answer
              }
              question
              order
            }
          }
          additionalQuestions {
            json
          }
          pageTitle
          node_locale
        }
      }
    }
  `);
  return allContentfulFaq.nodes.find(
    ({ node_locale: nodeLocale }: { node_locale: string }) =>
      nodeLocale === language
  );
};

const Container = styled.div({
  width: '992px',
  margin: '0 auto',
  [MEDIA_QUERY.TABLET]: {
    width: '690px',
  },
  [MEDIA_QUERY.MOBILE]: {
    width: 'auto',
    margin: 0,
  },
});

const H1 = styled.h1({
  fontWeight: 'bold',
  lineHeight: '54px',
  fontSize: '44px',
  margin: '10px 0 0 0',
  [MEDIA_QUERY.TABLET]: {
    lineHeight: '44px',
    fontSize: '36px',
    margin: '2px 40px 0 40px',
  },
  [MEDIA_QUERY.MOBILE]: {
    lineHeight: '32px',
    fontSize: '24px',
    margin: '36px 30px 0 30px',
  },
});

const CategoryContainer = styled.div(
  {
    [MEDIA_QUERY.MOBILE]: {
      display: 'block',
      margin: '36px 15px 0',
      ':first-child': {
        marginTop: 0,
      },
    },
  },
  ({ active }: { active: boolean }) =>
    !active && {
      [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
        display: 'none',
      },
    }
);

const Name = styled.h3({
  margin: 0,
  fontSize: '12px',
  lineHeight: '15px',
  letterSpacing: '1px',
  color: COLORS.OPAQUE_BLACK,
  paddingBottom: '10px',
  textTransform: 'uppercase',
  [MEDIA_QUERY.TABLET_AND_DESKTOP]: {
    display: 'none',
  },
});

const QuestionContainer = styled.div({
  padding: '0 12px',
  position: 'relative',
  cursor: 'pointer',
  userSelect: 'none',
  borderBottom: '1px solid rgba(0,0,0,0.1)',
  ':first-of-type': {
    borderTop: '1px solid rgba(0,0,0,0.1)',
  },
});

const Title = styled.p({
  fontSize: '18px',
  lineHeight: '24px',
  minHeight: '48px',
  position: 'relative',
  fontWeight: 'bold',
  padding: '11px 18px 11px 0',
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '16px',
    lineHeight: '24px',
    minHeight: 0,
    padding: '10px 15px 10px 0',
  },
});

const Answer = styled.div(
  {
    display: 'none',
    paddingBottom: '20px',
    color: COLORS.DARK_GREY,
    fontSize: '18px',
    lineHeight: '28px',
    [MEDIA_QUERY.MOBILE]: {
      fontSize: '16px',
      lineHeight: '24px',
      paddingBottom: '10px',
    },
  },
  ({ visible }: { visible: boolean }) => {
    return visible ? { display: 'block' } : {};
  }
);

const Open = styled.button(
  {
    width: '24px',
    height: '24px',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    cursor: 'pointer',
    transition: 'transform 0.3s ease-in-out',
    marginTop: '23px',
    svg: {
      width: '24px',
      height: '24px',
      verticalAlign: 'middle',
    },
    [MEDIA_QUERY.MOBILE]: {
      marginTop: '10px',
    },
  },
  ({ active }: ButtonProps) =>
    active
      ? {
          transform: 'rotate(180deg)',
        }
      : {
          transform: 'rotate(0deg)',
        }
);

const CategoryName = styled.div(
  {
    height: '40px',
    boxSizing: 'border-box',
    borderRadius: '20px',
    fontSize: '14px',
    lineHeight: '20px',
    padding: '10px 24px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    marginBottom: '5px',
    textAlign: 'left',
    letterSpacing: '1px',
    color: '#121212',
    cursor: 'pointer',
    [MEDIA_QUERY.TABLET]: {
      padding: '10px 12px',
    },
  },
  ({ active }: { active?: boolean }) =>
    active ? { backgroundColor: COLORS.LIGHT_BLUE } : {}
);

const NamesContainer = styled.div({
  display: 'flex',
  flexFlow: 'column nowrap',
  position: 'sticky',
  top: '126px',
  left: 0,
  width: '256px',
  alignSelf: 'flex-start',
  [MEDIA_QUERY.TABLET]: {
    width: '208px',
  },
  [MEDIA_QUERY.MOBILE]: {
    display: 'none',
  },
});

const QuestionExpandContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

type CategoryNamesProps = {
  categories: Category[];
  activeCategory: string;
  setActiveCategory: (name: string) => void;
};

const CategoryNames: React.FC<CategoryNamesProps> = ({
  categories,
  setActiveCategory,
  activeCategory,
}) => {
  return (
    <NamesContainer>
      {categories.map(({ name }, index) => {
        const slug = slugify(name);
        return (
          <CategoryName
            active={activeCategory === slug}
            onClick={() => setActiveCategory(slug)}
            key={`${name}-${index}`}
          >
            {name}
          </CategoryName>
        );
      })}
    </NamesContainer>
  );
};

const Question: React.FC<Question> = ({ question, answer }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <QuestionContainer id={question} onClick={() => setOpen(!isOpen)}>
      <QuestionExpandContainer>
        <Title>{question}</Title>
        <Open active={isOpen}>
          <Icon.Arrow />
        </Open>
      </QuestionExpandContainer>
      <Answer visible={isOpen}>
        <MarkdownRenderer markdown={answer.answer} />
      </Answer>
    </QuestionContainer>
  );
};

const Category: React.FC<Category & { active: boolean }> = ({
  questions,
  name,
  active,
}) => {
  const Questions = () =>
    questions
      .sort(q => q.order)
      .map((q, i) => <Question key={`${name}-${q.question}-${i}`} {...q} />);
  return (
    <CategoryContainer active={active}>
      <Name>{name}</Name>
      <Questions />
    </CategoryContainer>
  );
};

const Items = styled.div({
  margin: '86px auto 0 0',
  display: 'flex',
  flexWrap: 'nowrap',
  position: 'relative',
  justifyContent: 'space-between',
  [MEDIA_QUERY.TABLET]: {
    margin: '64px 0 0 0',
  },
  [MEDIA_QUERY.MOBILE]: {
    margin: '48px 0 0 0',
  },
});

const CategoriesContainer = styled.div({
  width: '608px',
  [MEDIA_QUERY.TABLET]: {
    width: '450px',
  },
  [MEDIA_QUERY.MOBILE]: {
    width: 'auto',
  },
});

const FAQ: React.FC<Props> = ({ language }) => {
  const { pageTitle, categories, additionalQuestions }: FaqPage = getData(
    language
  );
  const [activeCategory, setActiveCategory] = useState(
    slugify(get(categories, '[[0].name', ''))
  );

  const [activeMenuCategory, setActiveMenuCategory] = useState(activeCategory);

  const selectedCategory = useRef(activeCategory);

  const isOnTop = () => {
    if (typeof window !== 'undefined') return window.scrollY < 70;
    else return true;
  };

  const changeActiveCategory = () => {
    if (isOnTop) setActiveCategory(selectedCategory.current);
  };

  useEffect(() => {
    window.addEventListener('scroll', changeActiveCategory);
    return () => window.removeEventListener('scroll', changeActiveCategory);
  });

  const scrollToTop = () => {
    if (typeof window !== 'undefined')
      window.scroll({
        top: 0,
        left: window.screenLeft,
        behavior: 'smooth',
      });
  };

  const selectCategory = (category: string) => {
    if (category !== selectedCategory.current) {
      selectedCategory.current = category;
      setActiveMenuCategory(category);
      if (isOnTop()) setActiveCategory(category);
      else scrollToTop();
    }
  };

  const Categories = () =>
    categories.map(({ name, ...category }, i) => {
      const slug = slugify(name);
      return (
        <Category
          active={activeCategory === slug}
          key={`${name}-${i}`}
          name={name}
          {...category}
        />
      );
    });

  return (
    <Layout title={pageTitle} language={language}>
      <Container>
        <H1>{pageTitle}</H1>
        <Items>
          <CategoryNames
            activeCategory={activeMenuCategory}
            setActiveCategory={selectCategory}
            categories={categories}
          />
          <CategoriesContainer>
            <Categories />
          </CategoriesContainer>
        </Items>
        <AdditionalQuestions data={additionalQuestions} />
      </Container>
    </Layout>
  );
};

export default FAQ;
